.Container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0.2rem 0rem;
  overflow-y: hidden;
  height: 100%;
}

.formContainer {
  flex: 1;
  text-align: left;
  display: flex;
  max-height: 85vh;
  flex-direction: column;
  padding: 0rem 0.2rem 0rem 0rem;
  height: 100%;
}

.backlink {
  text-align: left;
  padding: 0.2rem 0.9375rem 0.2rem 0.9375rem;
}

.textField {
  padding: 0.2rem 0.9375rem 0.2rem 0.9375rem;
  text-align: left;
  display: block;
  box-sizing: border-box;
  textarea {
    resize: none;
    height: 15rem;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
  }
}

/* For screens with a min height of 990px */
@media (max-height: 990px) {
  .divider {
    height: 0px; /* Adjusted height for taller screens */
    margin-bottom: 0rem;
  }
}

.selectField {
  padding: 0rem 0.9375rem 0.2rem 0.9375rem;
  text-align: left;
  display: block;
  box-sizing: border-box;
}

.pagesContainer {
  margin-top: 10px;
  margin-bottom: 15px;
  align-items: left;
  height: 2.5rem;
  padding: 0.2rem 0.9375rem 0.2rem 0.9375rem;
  textarea {
    resize: none;
    height: 2.5rem;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
  }
}

.submitButton {
  align-self: flex-end;
  justify-self: center;
  margin-top: auto;
  width: 100%;
  button {
    width: 100%;
  }
}
