.form {
  position: relative;
  text-align: left;
}

.formTitle {
  margin-top: -3px;
  margin-bottom: -10px;
}

.fieldset {
  border: none;
  padding: 0;
}

.warningMessage {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

p {
  font-size: 19px;
  line-height: 1.25;
}
