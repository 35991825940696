$header-height: 50px;
$bundle-information-bar-height: 56px;
$key-event-tab-height: 34px;
$key-event-header-height: 85px;
$key-event-processing-text-height: 40px;
$document-information-padding: 17.5px;

.container {
  text-align: left;
  background-color: #efefef;
  border-left: 1px solid #b1b4b6;
  display: block;
  height: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  display: grid;
  margin-bottom: 15px;
  grid-template-rows:
    minmax(min-content, max-content) minmax(min-content, max-content)
    minmax(min-content, max-content);
}

.viewContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.containerHeight {
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      ($document-information-padding * 2)
  );
}

.divider {
  width: 100%;
  border: 0;
  border-top: 1px solid #b1b4b6;
  margin-bottom: 40px;
}

.heading {
  text-align: left;
}

.submitButton {
  align-self: flex-end;
  justify-self: center;
  width: 100%;
  button {
    width: 100%;
  }
}

.scroll {
  overflow: scroll;
}

.loaderText {
  background-color: #1d70b8;
  color: #fff;
  height: 40px;
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 1rem;
}

.listTopPanel {
  padding: 5px 0px 0px 5px;
  font-size: 1rem;
  div {
    width: 95%;
    padding-left: 5px;
  }
}

.keyEventsContainer {
  //width: 19rem;
  padding: 0px 15px 0px 15px;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      $key-event-tab-height - $key-event-header-height
  );
}

.keyEventsList {
  padding-left: 0px;
}

.bolderText {
  font-weight: bold;
  font-size: 1.1rem;
}

.spinner {
  padding-left: 5px;
  position: relative;
  vertical-align: sub;
  display: inline !important;
}

.noEventsContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      $key-event-tab-height - $key-event-header-height
  );
}

.editEventsContainer {
  text-align: left;
  background-color: #efefef;
  border-left: 1px solid #b1b4b6;
  display: block;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.viewEditEventsContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: auto;
}
