.details {
  padding: none !important;
  span {
    text-decoration: none !important;
  }

  > div:first-of-type {
    border-left: none;
    padding: 5px 2px 0px 15px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    width: 100%;
  }
}
